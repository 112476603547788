/* eslint-disable global-require */
import React from 'react';
import styled from 'styled-components';
import useTranslation from '../hooks/useTranslation';
import PerfectImageV2 from './PerfectImageV2';
import {
  APPLE_STORE_URL,
  PLAY_STORE_URL,
  SCREENSHOT_URL,
} from '../utils/constants';
import { LocalesUnion } from '../types/interfaces';

interface Props {
  locale: LocalesUnion;
}

const PlayBadge = styled(PerfectImageV2)`
  width: 158px;
  height: 61px;
  margin-top: -10px;
  margin-left: -10px;
`;

const AppleBadge = styled(PerfectImageV2)`
  width: 140px;
  height: 41px;
  margin-left: 10px;
  margin-right: 10px;
`;

const ScreenShot = styled(PerfectImageV2)`
  width: 300px;
  height: 580px;
  align-self: center;
`;

const FooterTitle = styled.p`
  font-size: 24px;
`;

const Footer: React.FC<Props> = ({ locale = 'en' }) => {
  const { t } = useTranslation(locale);

  return (
    <div className="bg-primary p-4">
      <FooterTitle className="text-white text-center mt-2 mb-4">
        {t('FOOTER_TITLE')}
      </FooterTitle>
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <ScreenShot
            alt="Démo de l'application"
            width={300}
            src={SCREENSHOT_URL}
            height={580}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <a
          href="https://apps.apple.com/us/app/deevent/id1519499216?ign-mpt=uo%3D2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppleBadge
            width={158}
            height={41}
            alt="Apple Store icon"
            src={APPLE_STORE_URL}
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=app.deevent"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PlayBadge
            alt="Google Play Store icon"
            src={PLAY_STORE_URL}
            width={158}
            height={61}
          />
        </a>
      </div>
      <div className="mt-4">
        <p className="text-white text-center">
          {`Copyright © ${new Date().getFullYear()} Deevent`}
        </p>
      </div>
    </div>
  );
};

export default Footer;
