export const buildCloudinaryUrl = (
  url: string,
  format = 'jpg',
  width = 360
) => {
  if (url.indexOf('cloudinary') === -1) {
    return url;
  }

  const formatUrl = url.replace('.jpg', `.${format}`);

  return `https://res.cloudinary.com/deevent-app/image/fetch/q_auto,c_fill,w_${
    width || 360
  }/${formatUrl}`;
};
