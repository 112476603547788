/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useState, useCallback } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { buildCloudinaryUrl } from '../helpers/cloudinary-helper';

interface Props {
  style?: object;
  className?: string;
  alt: string;
  src: string;
  containerClassName?: string;
  width: number;
  height?: number;
}

const initialBlur = 10;

const PerfectImageV2: React.FC<Props> = (props) => {
  const [blur, setBlur] = useState(initialBlur);
  const [loaded, setLoaded] = useState(false);
  const {
    style,
    className,
    alt,
    src,
    containerClassName = '',
    width,
    height,
  } = props;

  const getBlurStyle = () => {
    return {
      filter: `blur(${blur}px)`,
      transition: 'all 0.2s ease-in-out',
      width: '100%',
    };
  };

  const getStyle = () => {
    return {
      filter: `blur(${blur}px)`,
      transition: 'all 0.2s ease-in-out',
      transform: `scale(1.${blur ? '02' : '0'})`,
      // objectFit: 'cover',
      ...style,
    };
  };

  const fetch = useCallback(() => {
    const loadImg = (url: string) => {
      const image = new Image();

      image.src = url;

      image.addEventListener(
        'load',
        () => {
          setLoaded(true);
          setBlur(0);
        },
        false
      );
    };

    if (src) {
      loadImg(buildCloudinaryUrl(src, 'jpg', Math.round(width * 2)));
    } else {
      setLoaded(true);
      setBlur(0);
    }
  }, [src, width]);

  if (!src) {
    return (
      <img
        alt={alt}
        className={className}
        style={{ ...style, objectFit: 'cover' }}
        src="/placeholder.svg"
        width={width}
        height={height}
      />
    );
  }

  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => {
        if (isVisible && Boolean(blur)) {
          fetch();
        }

        if (!loaded) {
          return (
            <div className={containerClassName} style={getBlurStyle()}>
              <img
                style={getStyle()}
                src={buildCloudinaryUrl(src, 'jpg', 20)}
                className={className}
                width={width}
                height={height}
                alt={alt}
              />
            </div>
          );
        }

        return (
          <div className={containerClassName} style={getBlurStyle()}>
            <picture>
              <source
                type="image/jpeg"
                srcSet={
                  (buildCloudinaryUrl(src, 'jpg', width),
                  buildCloudinaryUrl(src, 'jpg', Math.round(width * 2)))
                }
              />
              <source
                type="image/webp"
                srcSet={
                  (buildCloudinaryUrl(src, 'webp', width),
                  buildCloudinaryUrl(src, 'webp', Math.round(width * 2)))
                }
              />
              <img
                src={buildCloudinaryUrl(src, 'jpg', width)}
                style={getStyle()}
                className={className}
                alt={alt}
              />
            </picture>
          </div>
        );
      }}
    </VisibilitySensor>
  );
};

export default PerfectImageV2;
