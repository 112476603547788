import React, { useEffect, useCallback, useState } from 'react';
import Head from 'next/head';
import Header from './Header';
import Footer from './Footer';
import { initGA, logPageView } from '../utils/analytics';
import { createLdJsonSchema } from '../utils/schema-helper';
import { LocalesUnion } from '../types/interfaces';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    GA_INITIALIZED: boolean;
  }
}

interface Props {
  locale: LocalesUnion;
  image?: string;
  path: string;
  description: string;
  title: string;
  creationDate?: string;
  author?: string;
  headerCollapsed?: boolean;
  standalone?: boolean;
}

const Layout: React.FC<Props> = ({
  title,
  description,
  path,
  image = 'https://deevent.app/images/mountain.jpg',
  creationDate,
  author = 'Nicolas Meienberger',
  standalone,
  ...props
}) => {
  const { locale, children, headerCollapsed: collapsed } = props;
  const [headerCollapsed, setHeaderCollapsed] = useState(Boolean(collapsed));

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  }, []);

  const handleScroll = useCallback(
    (pos) => {
      if (pos > 80) {
        setHeaderCollapsed(true);
      } else if (!headerCollapsed) {
        setHeaderCollapsed(false);
      }
    },
    [headerCollapsed]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll(window.scrollY));
    document.body.addEventListener('scroll', () =>
      handleScroll(document.body.scrollTop)
    );

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const schema = createLdJsonSchema({
    title,
    description,
    path,
    image,
    creationDate,
    locale,
    author,
  });

  return (
    <>
      <Head>
        <>
          <title>{title || 'Deevent'}</title>
          <script
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: schema,
            }}
            type="application/ld+json"
          />
          <meta name="description" content={description} />
          <meta property="og:title" content={title || 'Deevent'} key="title" />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={`https://deevent.app${path}`} />
          <meta property="og:image" content={image} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="825" />
          <meta property="og:type" content="article" />
          <meta property="twitter:url" content={`https://deevent.app${path}`} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title || 'Deevent'} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image} />
        </>
      </Head>
      {!standalone && <Header locale={locale} collapsed={headerCollapsed} />}
      <div>{children}</div>
      {!standalone && <Footer locale={locale} />}
    </>
  );
};

export default Layout;
